import React from 'react'

const About = () => {
    return (
      <div className='container'>
        <h1>
          About
        </h1>
        <h5>CPSC 2600 Assignment 6</h5>
      </div>
    )
}

export default About
